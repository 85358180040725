<template>
  <div class="avgTable">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th></th>
          <th v-for="(test, index) in tests" :key="index">
            <el-tooltip effect="dark" :content="test.title" placement="top">
              <div class="text-success">TEST {{ index }}</div>
            </el-tooltip>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><b>AVG</b></td>
          <td v-for="(exam, index) in tests" :key="index">
            <sat-score
              v-if="exam.score.avg_english_reading_score"
              :rscore="
                showScore(
                  parseFloat(exam.score.avg_english_reading_score) * 10,
                  1
                )
              "
              :wscore="
                showScore(
                  parseFloat(exam.score.avg_english_writing_score) * 10,
                  1
                )
              "
              :escore="
                showScore(parseFloat(exam.score.avg_english_reading_score), 1) *
                  10 +
                  showScore(
                    parseFloat(exam.score.avg_english_writing_score),
                    1
                  ) *
                    10
              "
              :mscore="showScore(parseFloat(exam.score.avg_math_score), 1)"
              :tscore="
                showScore(parseFloat(exam.score.avg_english_reading_score), 1) *
                  10 +
                  showScore(
                    parseFloat(exam.score.avg_english_writing_score),
                    1
                  ) *
                    10 +
                  showScore(parseFloat(exam.score.avg_math_score), 1)
              "
              :rscore_up="0"
              :gscore_up="0"
              :escore_up="0"
              :mscore_up="0"
              :score_up="0"
            ></sat-score>
            <div v-else> - </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SatScore from "@/views/sessionClasses/components/SatScore.vue";

export default {
  components: {
    SatScore
  },

  mixins: [],

  props: ["tests"],
  data() {
    return {
      section: {
        count: 16,
        exams: [
          {
            id: 2308,
            exam_category_id: 2,
            title: "CB Khan SAT Test 06",
            created_at: "2020-11-10T03:58:02.000000Z",
            updated_at: "2020-11-10T03:58:02.000000Z",
            laravel_through_key: 168,
            full_name: "CB Khan SAT Test 06",
            r_total: 0,
            g_total: 0,
            e_total: 0,
            m_total: 0,
            t_total: 0,
            r_up: 0,
            g_up: 0,
            e_up: 0,
            m_up: 0,
            t_up: 0
          },
          {
            id: 2310,
            exam_category_id: 2,
            title: "CB Khan SAT Test 08",
            created_at: "2020-11-10T03:58:03.000000Z",
            updated_at: "2020-11-10T03:58:03.000000Z",
            laravel_through_key: 168,
            full_name: "CB Khan SAT Test 08",
            r_total: 297,
            g_total: 296,
            e_total: 5930,
            m_total: 7770,
            t_total: 13700,
            r_up: 0,
            g_up: 0,
            e_up: 0,
            m_up: 0,
            t_up: 0
          },
          {
            id: 2322,
            exam_category_id: 2,
            title: "201805 May SAT (US)",
            created_at: "2020-11-10T03:58:10.000000Z",
            updated_at: "2020-11-10T03:58:10.000000Z",
            laravel_through_key: 168,
            full_name: "201805 May SAT (US)",
            r_total: 412,
            g_total: 458,
            e_total: 8700,
            m_total: 10570,
            t_total: 19270,
            r_up: 72,
            g_up: 101,
            e_up: 173,
            m_up: 175,
            t_up: 348
          },
          {
            id: 2326,
            exam_category_id: 2,
            title: "201812 December SAT (IN)",
            created_at: "2020-11-10T03:58:12.000000Z",
            updated_at: "2020-11-10T03:58:12.000000Z",
            laravel_through_key: 168,
            full_name: "201812 December SAT (IN)",
            r_total: 438,
            g_total: 460,
            e_total: 8980,
            m_total: 10820,
            t_total: 19800,
            r_up: 16,
            g_up: 2,
            e_up: 18,
            m_up: 15,
            t_up: 33
          },
          {
            id: 2329,
            exam_category_id: 2,
            title: "201904 April SAT (US)",
            created_at: "2020-11-10T03:58:14.000000Z",
            updated_at: "2020-11-10T03:58:14.000000Z",
            laravel_through_key: 168,
            full_name: "201904 April SAT (US)",
            r_total: 329,
            g_total: 410,
            e_total: 7390,
            m_total: 9110,
            t_total: 16500,
            r_up: -68,
            g_up: -32,
            e_up: -100,
            m_up: -107,
            t_up: -207
          },
          {
            id: 2334,
            exam_category_id: 2,
            title: "201910 October SAT (US)",
            created_at: "2020-11-10T03:58:17.000000Z",
            updated_at: "2020-11-10T03:58:17.000000Z",
            laravel_through_key: 168,
            full_name: "201910 October SAT (US)",
            r_total: 394,
            g_total: 408,
            e_total: 8020,
            m_total: 8490,
            t_total: 16510,
            r_up: 40,
            g_up: -1,
            e_up: 39,
            m_up: -38,
            t_up: 1
          },
          {
            id: 2335,
            exam_category_id: 2,
            title: "201911 November SAT (US)",
            created_at: "2020-11-10T03:58:18.000000Z",
            updated_at: "2020-11-10T03:58:18.000000Z",
            laravel_through_key: 168,
            full_name: "201911 November SAT (US)",
            r_total: 344,
            g_total: 370,
            e_total: 7140,
            m_total: 7620,
            t_total: 14760,
            r_up: -31,
            g_up: -24,
            e_up: -55,
            m_up: -55,
            t_up: -110
          }
        ]
      }
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    showScore(total, count) {
      let score = "";
      if (count > 0) {
        score = Math.round(total / count);
      }
      return score;
    }
  }
};
</script>

<style scoped>
.table td {
  padding: 5px;
  vertical-align: middle;
  text-align: center;
}
.table th,
.table td {
  text-align: center;
  border-top: none;
  white-space: nowrap;
}
.avgTable {
  width: 100%;
  overflow: auto;
}
</style>
