var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analysis"},[_c('h2',[_vm._v("Class Analysis")]),_c('hr'),_c('avg-table',{attrs:{"tests":_vm.tests}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"history"},[_c('hr'),_c('h4',{staticClass:"title",staticStyle:{"color":"#43a06b","margin":"20px 0 0 0"}},[_vm._v(" Score Analysis ")]),_c('el-tabs',{staticStyle:{"margin-top":"20px"},on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeReport),callback:function ($$v) {_vm.activeReport=$$v},expression:"activeReport"}},[_c('el-tab-pane',{attrs:{"label":"SAT","name":"reportSAT"}},[_c('div',{staticStyle:{"overflow":"auto"}},[_c('div',{style:({
              width: '1180px',
              height: '500px',
              margin: '0 auto'
            }),attrs:{"id":"reportSAT"}})])]),_c('el-tab-pane',{attrs:{"label":"ACT","name":"reportACT"}},[_c('div',{staticStyle:{"overflow":"auto"}},[_c('div',{style:({
              width: '1180px',
              height: '500px',
              margin: '0 auto'
            }),attrs:{"id":"reportACT"}})])]),_c('el-tab-pane',{attrs:{"label":"TOEFL","name":"reportTOEFL"}},[_c('div',{staticStyle:{"overflow":"auto"}},[_c('div',{style:({
              width: '1180px',
              height: '500px',
              margin: '0 auto'
            }),attrs:{"id":"reportTOEFL"}})])])],1)],1),_c('hr'),_c('table',{staticClass:"table table-bordered"},[_vm._m(0),_c('tbody',_vm._l((_vm.tests),function(test,index){return _c('tr',{key:index},[_c('td',[_c('a',{attrs:{"target":"_blank","href":'/session-classes/analysis/detail?class_id=' +
                _vm.classId +
                '&week=' +
                test.which_week}},[_vm._v(" Test "+_vm._s(test.which_week)+": "+_vm._s(test.title)+" ")])]),_c('td',[(test.score.min_reading_score)?_c('div',[_vm._v(" "+_vm._s(test.score.min_reading_score + test.score.min_writing_score)+" ~ "+_vm._s(test.score.max_reading_score + test.score.max_reading_score)+" ")]):_c('div',[_vm._v(" - ")])]),_c('td',[(test.score.min_math_score)?_c('div',[_vm._v(" "+_vm._s(test.score.min_math_score)+" ~ "+_vm._s(test.score.max_math_score)+" ")]):_c('div',[_vm._v(" - ")])]),_c('td',[(test.score.min_total_score)?_c('div',[_vm._v(" "+_vm._s(test.score.min_total_score)+" ~ "+_vm._s(test.score.max_total_score)+" ")]):_c('div',[_vm._v(" - ")])])])}),0)]),_c('h5',{staticStyle:{"margin-top":"20px"}},[_vm._v("* (Minimum score ~ Highest mark)")])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Test")]),_c('th',[_vm._v("English")]),_c('th',[_vm._v("Math")]),_c('th',[_vm._v("Score")])])])
}]

export { render, staticRenderFns }