var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"avgTable"},[_c('table',{staticClass:"table table-bordered"},[_c('thead',[_c('tr',[_c('th'),_vm._l((_vm.tests),function(test,index){return _c('th',{key:index},[_c('el-tooltip',{attrs:{"effect":"dark","content":test.title,"placement":"top"}},[_c('div',{staticClass:"text-success"},[_vm._v("TEST "+_vm._s(index))])])],1)})],2)]),_c('tbody',[_c('tr',[_vm._m(0),_vm._l((_vm.tests),function(exam,index){return _c('td',{key:index},[(exam.score.avg_english_reading_score)?_c('sat-score',{attrs:{"rscore":_vm.showScore(
                parseFloat(exam.score.avg_english_reading_score) * 10,
                1
              ),"wscore":_vm.showScore(
                parseFloat(exam.score.avg_english_writing_score) * 10,
                1
              ),"escore":_vm.showScore(parseFloat(exam.score.avg_english_reading_score), 1) *
                10 +
                _vm.showScore(
                  parseFloat(exam.score.avg_english_writing_score),
                  1
                ) *
                  10,"mscore":_vm.showScore(parseFloat(exam.score.avg_math_score), 1),"tscore":_vm.showScore(parseFloat(exam.score.avg_english_reading_score), 1) *
                10 +
                _vm.showScore(
                  parseFloat(exam.score.avg_english_writing_score),
                  1
                ) *
                  10 +
                _vm.showScore(parseFloat(exam.score.avg_math_score), 1),"rscore_up":0,"gscore_up":0,"escore_up":0,"mscore_up":0,"score_up":0}}):_c('div',[_vm._v(" - ")])],1)})],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('b',[_vm._v("AVG")])])
}]

export { render, staticRenderFns }