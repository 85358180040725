<template>
  <div class="analysis">
    <h2>Class Analysis</h2>
    <hr />
    <avg-table :tests="tests"></avg-table>
    <div class="history" v-show="false">
      <hr />
      <h4 class="title" style="color:#43a06b;margin:20px 0 0 0">
        Score Analysis
      </h4>
      <el-tabs v-model="activeReport" @tab-click="handleClick" style="margin-top:20px">
        <el-tab-pane label="SAT" name="reportSAT">
          <div style="overflow:auto;">
            <div
              id="reportSAT"
              :style="{
                width: '1180px',
                height: '500px',
                margin: '0 auto'
              }"
            ></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="ACT" name="reportACT">
          <div style="overflow:auto">
            <div
              id="reportACT"
              :style="{
                width: '1180px',
                height: '500px',
                margin: '0 auto'
              }"
            ></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="TOEFL" name="reportTOEFL">
          <div style="overflow:auto">
            <div
              id="reportTOEFL"
              :style="{
                width: '1180px',
                height: '500px',
                margin: '0 auto'
              }"
            ></div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <hr />
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Test</th>
          <th>English</th>
          <th>Math</th>
          <th>Score</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(test, index) in tests" :key="index">
          <td>
            <a
              target="_blank"
              :href="
                '/session-classes/analysis/detail?class_id=' +
                  classId +
                  '&week=' +
                  test.which_week
              "
            >
              Test {{ test.which_week }}: {{ test.title }}
            </a>
          </td>
          <td>
            <div v-if="test.score.min_reading_score">
              {{ test.score.min_reading_score + test.score.min_writing_score }}
              ~
              {{ test.score.max_reading_score + test.score.max_reading_score }}
            </div>
            <div v-else>
              - 
            </div>
          </td>
          <td>
            <div v-if="test.score.min_math_score">
              {{ test.score.min_math_score }}
              ~
              {{ test.score.max_math_score }}
            </div>
            <div v-else>
              - 
            </div>
          </td>
          <td>
            <div v-if="test.score.min_total_score">
              {{ test.score.min_total_score }}
              ~
              {{ test.score.max_total_score }}
            </div>
            <div v-else>
              -
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <h5 style="margin-top: 20px">* (Minimum score ~ Highest mark)</h5>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import echarts from "echarts";
import courseApi from "@/apis/course";
import AvgTable from "@/views/sessionClasses/analysis/components/AvgTable";

export default {
  metaInfo() {
    return {
      title: "Analysis - Ivy-Way Academy"
    };
  },

  components: {
    AvgTable
  },

  mixins: [],

  props: [],
  data() {
    return {
      activeReport: "reportSAT",
      tests: []
    };
  },
  computed: {
    classId() {
      let classId = 0;
      if (this.$route.query.class_id) {
        classId = this.$route.query.class_id;
      }
      return classId;
    }
  },
  watch: {},

  mounted() {
    this.$nextTick(function() {
      this.draw("reportSAT");
    });
    this.getClassExams();
  },

  methods: {
    handleClick(val) {
      console.log(this.activeReport);
      this.draw(this.activeReport);
    },
    async getClassExams() {
      this.tests = await courseApi.getClassExams(this.classId);
    },
    draw(id) {
      this.charts = echarts.init(document.getElementById(id));
      let vm = this;
      if(id === "reportSAT"){
        this.charts.setOption({
          xAxis: {
            type: "category",
            data: ["Test 1", "Test 2", "Test 3", "Test 4", "Test 5", "Test 6", "Test 7"]
          },
          yAxis: {
            type: "value",
            max: 1600,
            min: 600,
            maxInterval: 200,
            minInterval: 1
          },
          series: [
            {
              data: [1246, 1285, 1236, 1271, 1263, 1235, 1384],
              type: "line"
            }
          ]
        });
      }
      if(id === "reportACT"){
        this.charts.setOption({
          xAxis: {
            type: "category",
            data: ["Test 1", "Test 2", "Test 3", "Test 4", "Test 5", "Test 6", "Test 7"]
          },
          yAxis: {
            type: "value",
            max: 36,
            min: 0,
            maxInterval: 6,
            minInterval: 1
          },
          series: [
            {
              data: [29, 30, 23, 24, 29, 32, 33],
              type: "line"
            }
          ]
        });
      }
      if(id === "reportTOEFL"){
        this.charts.setOption({
          xAxis: {
            type: "category",
            data: ["Test 1", "Test 2", "Test 3", "Test 4", "Test 5", "Test 6", "Test 7"]
          },
          yAxis: {
            type: "value",
            max: 120,
            min: 0,
            maxInterval: 10,
            minInterval: 1
          },
          series: [
            {
              data: [80, 90, 85, 95, 101, 112, 105],
              type: "line"
            }
          ]
        });
      }
    }
  }
};
</script>

<style scoped>
hr {
  border-top: 2px solid #42a16a;
}
.analysis {
  padding: 20px;
}
.analysis /deep/ .el-table th,
.analysis /deep/.el-table td {
  padding: 10px;
  font-size: 16px;
}
</style>
